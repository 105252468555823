import React from 'react';

import Layout from '../../components/theme/Layout'
import Header from '../../components/theme/Header'
import GotchaDayPage from "../../components/pages/GotchaDayPage";
import Footer from "../../components/theme/Footer";

const Gotcha = () => {
  return (
    <Layout
      meta={{
        title: 'Gotcha Day | ROYALE®'
      }}
      urls={{
        'en': `/gotchaday`,
        'fr': `/fr/gotchaday`
      }}
      locale='en'
    >
      <Header
        urls={{
          'en': `/gotchaday`,
          'fr': `/fr/gotchaday`
        }}
        locale='en'
      />
      <GotchaDayPage locale='en' />

      <Footer hideSignUp />
    </Layout>
  )
}

export default Gotcha
